import React from "react"

import * as styles from './cartouche.module.scss'

const Cartouche = ({ children, classes }) => {
  return (
    <>
      <div className={`${styles.cartouche} ${styles[classes]}`}>
        {children}
      </div>
    </>
  )
}

export default Cartouche
