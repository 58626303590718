import React, { useState, useLayoutEffect } from 'react'

import * as styles from './galerie.module.scss'
import Close from './close.svg'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (data) => {
  if (!data) return null

  const [state, setState] = useState({
    focusImage: undefined
  });

  const {images} = data;

  const focusImage = (e, image) => {
    setState({ focusImage: image });

    e.preventDefault();
  }

  const exitFocusImage = (e, image) => {
    setState({ focusImage: undefined });

    e.preventDefault();
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (state.focusImage !== undefined) {
        setState({ focusImage: undefined });
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <>
      <div className={`${styles.focusContainer} ${state.focusImage ? styles.focusContainerVisible : ''}`}>
          {
            state.focusImage && (
              <>
              <Close className={styles.closeButton}
                onKeyDown={exitFocusImage}
                onClick={exitFocusImage}/>
                <div className={styles.focusContent}>
                  <figure className={styles.figure}>
                    <img src={state.focusImage.localFile.publicURL} alt={state.focusImage.altText} />
                  </figure>
                </div>
                <button className={styles.focusBackdrop}
                  onKeyDown={exitFocusImage}
                  onClick={exitFocusImage}>
                </button>
              </>
            )
          }

      </div>
      <div className={styles.galerie}>
        {
          images.map((image, index) => {
            return (
              <button key={index} className={styles.figureContainer} onClick={(e) => { focusImage(e, image) }} onKeyDown={(e) => { focusImage(e, image) }}>
                <figure className={styles.figure}>
                  <img src={image.localFile.publicURL} alt={image.altText} />
                  {
                    image.description && (
                      <p className={styles.description} dangerouslySetInnerHTML={{ __html: image.description }}>
                      </p>
                    )
                  }
                </figure>
                {
                  image.caption && (
                    <figcaption
                      className={styles.figureCaption} dangerouslySetInnerHTML={{ __html: image.caption }}>
                    </figcaption>
                  )
                }
              </button>
            );
          })
        }
      </div>
    </>
  )
}

