import React from 'react'
import { Link, graphql } from "gatsby"

import Cartouche from "../components/cartouche"
import Galerie from "../components/galerie"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ProjectTemplate({ data }) {
  if (!data) return null

  let scrollProjectBelowContent = true;
  const containerDivRef = React.createRef();

  const {
    title,
    content,
    featuredImage,
    projectDetails,
  } = data.wpProject

  const setScrollBelowContent = (scrollBelowContent) => {
    scrollProjectBelowContent = scrollBelowContent

    if (scrollProjectBelowContent) {
      containerDivRef.current.classList.add("visible");
    } else {
      containerDivRef.current.classList.remove("visible");
    }
  }

  return (
    <Layout featuredImage={featuredImage} setScrollBelowContent={setScrollBelowContent}>
      <Seo title={title} />
      <div className="container mb">
        <div className="row">
          <div className="column-left">
            <div ref={containerDivRef} className="fade sticky-top d-mobile-none">
              <Link to="/projets"
                className="text-decoration-none">
                ← retour au projets
              </Link>

              <p className="h2 mt">{title}</p>

              <div dangerouslySetInnerHTML={{ __html: content }} className="mb"></div>
            </div>
          </div>
          <div className={`column-right ${featuredImage ? 'mt-negative' : 'mt'}`}>
            <Cartouche>
              <h1>{title}</h1>
                {projectDetails.year &&
                  <div>
                    <h3 className="mb-0 text-muted font-weight-light">année de réalisation</h3>
                    <p className="h3  mb-0">{projectDetails.year}</p>
                    <br />
                  </div>
                }
                {projectDetails.localisation &&
                  <div>
                    <h3 className="mb-0 text-muted font-weight-light">localisation</h3>
                    <p className="h3  mb-0">{projectDetails.localisation}</p>
                  </div>
                }
            </Cartouche>
            {projectDetails.galerie && (
              <Galerie images={projectDetails.galerie}></Galerie>
              )
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getProject($id: String!) {
      wpProject(id: {eq: $id}) {
        id
        title
        content
        projectDetails {
          year
          localisation
          galerie {
            altText
            description
            caption
            localFile {
              publicURL
            }
          }
          documents {
            localFile {
              publicURL
            }
            title
          }
        }
        featuredImage {
          node {
            imageCustomFields {
              position
            }
            localFile {
              publicURL
            }
          }
        }
        projectCategories {
          nodes {
            slug
            name
            uri
          }
        }
      }
    }
`
