// extracted by mini-css-extract-plugin
export var focusContainer = "galerie-module--focusContainer--C4h5L";
export var focusContainerVisible = "galerie-module--focusContainerVisible--czDNs";
export var focusBackdrop = "galerie-module--focusBackdrop--xYkYo";
export var focusContent = "galerie-module--focusContent--Y8rgB";
export var figure = "galerie-module--figure--fptnt";
export var closeButton = "galerie-module--closeButton--nzZ-6";
export var galerie = "galerie-module--galerie--EJods";
export var figureContainer = "galerie-module--figure-container--73xqU";
export var figureCaption = "galerie-module--figure-caption--XG-i1";
export var description = "galerie-module--description--3TWLG";